export default class CreateCompanyRequestDTO {
    public name: string | null = null;

    public ein: string | null = null;

    public resident = true;

    public nationalTin: string | null = null;

    public phone: string | null = null;

    public email: string | null = null;

    public client = true;

    public isMerge = false;

    constructor(init?: Partial<CreateCompanyRequestDTO>) {
        Object.assign(this, init)
    }
}